import React, { useState, useRef } from 'react';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';


const CreateTask = ({ modalShow, onClose, onComplete }) => {
  const [name, setName] = useState('');
  const [rootUrl, setRootUrl] = useState('');

  function onCreateClick() {
    onComplete(name, rootUrl);
  }

  const onNameChange = (e) => {
    setName(e.target.value);
  }

  const onRootUrlChange = (e) => {
    setRootUrl(e.target.value);
  }

  return (
    <div>

      <Modal show={modalShow} size="lg" aria-labelledby="contained-modal-title-vcenter" centered >
        <Modal.Header>
          <h6> Create a new learning task </h6>
        </Modal.Header>
        <Modal.Body>
          <InputGroup className="mb-3">
            <InputGroup.Text id="basic-addon"> Task Name </InputGroup.Text>
            <Form.Control
              placeholder=""
              aria-label="TaskName"
              aria-describedby="basic-addon"
              type="text"
              value={name}
              onChange={onNameChange}
              autoComplete="new-password"
            />
          </InputGroup>

          <InputGroup className="mb-3">
            <InputGroup.Text id="basic-addon"> Root Url </InputGroup.Text>
            <Form.Control
              placeholder=""
              aria-label="RootUrl"
              aria-describedby="basic-addon"
              type="text"
              value={rootUrl}
              onChange={onRootUrlChange}
              autoComplete="new-password"
            />
          </InputGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={onCreateClick} variant="outline-success"> Create </Button>
          <Button onClick={() => { onClose(); }} variant="outline-success"> Cancel </Button>
        </Modal.Footer>
      </Modal>

    </div>
  );
};

export default CreateTask;
