import { useEffect, useState, useRef } from 'react';
import { useNavigate, NavLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18n from '../i18n';

import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavItem from 'react-bootstrap/NavItem';
import { Container, NavDropdown } from 'react-bootstrap';


function Head() {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);
  const node = useRef();

  const [nickname, setNickname] = useState('');
  const navigate = useNavigate();

  const location = useLocation();
  const queryParams = location.search;

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  }

  const initLanguage = () => {
    const savedLanguage = localStorage.getItem('language');
    if (savedLanguage) {
      changeLanguage(savedLanguage);
    } else {
      if (navigator.language === 'zh-CN' || navigator.language === 'zh-cn') {
        changeLanguage('cn');
      } else {
        changeLanguage('en');
      }
    }
  }

  useEffect(() => {
    // Add event listener for clicks
    document.addEventListener("mousedown", handleClick);
    initLanguage();
    // const savedName = localStorage.getItem('nickname');
    // const userId = localStorage.getItem('userid');
    const savedName = 'Derek';
    const userId = 'UserId';
    if (savedName && userId) {
      setNickname(savedName);
    } else {
      // jump to login page
      let jumpUrl = '/login';
      if (queryParams) {
        jumpUrl += queryParams;
      }
      navigate(jumpUrl);
    }

    // Cleanup
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  const handleClick = (e) => {
    // If the clicked area is outside the navbar, close the navbar
    if (node.current.contains(e.target)) {
        // Inside click
        return;
    }
    // Outside click
    setExpanded(false);
  };

  const handleLogout = () => {
    localStorage.removeItem('userid');
    localStorage.removeItem('nickname');
  }

  const toggleNavbar = () => {
    setExpanded(expanded ? false : "expanded")
  }

  return (
    <header className="App-header" ref={node}>
      <div className='head-frame'>
      <Navbar bg="info" fixed='top' expand='lg' expanded={expanded} className='navbar-expand-lg my-navbar'>
        <Container fluid>
          <Navbar.Brand href="/">
            <img className='head-image' src='/images/head-logo.png' alt=''></img>
            {' '}Mangosteen
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={toggleNavbar} />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className='me-auto'>

              <NavItem>
                <Nav.Link as={NavLink} to='/'> Learning </Nav.Link>
              </NavItem>

              <NavItem>
                <Nav.Link as={NavLink} to='/chat'> Chat </Nav.Link>
              </NavItem>

            </Nav>

            <Nav>
              <NavDropdown title={t('menu_my')} id="basic-nav-dropdown" align='end'>
                <NavDropdown.Item href='/account'>{nickname}</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href='/login' onClick={handleLogout}>Logout</NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      </div>
    </header>
  );
}

export default Head;
