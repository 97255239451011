import { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import configData from '../config.json';

import Head from '../components/Head';
import Tail from "../components/Tail";
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import CreateTask from '../components/CreateTask'


function HomePage() {
  // const navigate = useNavigate();
  // const [taskName, setTaskName] = useState('');
  const [learningTasks, setLearningTasks] = useState([]);

  const [showCreateTaskModal, setShowCreateTaskModal] = useState(false);

  // const userId = localStorage.getItem('userid');
  const userId = 'UserId';

  function apiCreateLearnTask(name, url) {
    const requestData = {
      name: name,
      url: url,
    };

    fetch(configData.API_URL + '/knowlib/task', {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Authorization': `Bearer ${userId}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestData)
    })
      .then(response => response.json())
      .then(data => {
        console.log(data);
        if (data.success) {
          alert(data.message);
          apiGetLearnTaskList(userId);
        } else {
          alert(data.message);
        }
      })
      .catch(error => console.log(error));
  }

  const apiGetLearnTaskList = (userId) => {
    fetch(configData.API_URL + `/knowlib/tasks`,
      {
        redirect: 'manual',
        headers: {
          'Authorization': `Bearer ${userId}`,
          'Content-Type': 'application/json',
        },
      })
      .then(response => response.json())
      .then(data => {
        console.log(data);
        setLearningTasks(data);
      })
      .catch(error => console.log(error));
  }

  useEffect(() => {
    apiGetLearnTaskList(userId);

    return () => {
    }
  }, []);


  function toLocalTime(utcDateString) {
    const utcDate = new Date(utcDateString);
    // Convert UTC to local time using toLocaleString
    const localTimeString = utcDate.toLocaleString();
    return localTimeString;
  }

  const onCreateTaskClick = () => {
    setShowCreateTaskModal(true);
  }

  const onCreateTaskModalClose = () => {
    setShowCreateTaskModal(false);
  }

  const onCreateTaskComplete = (name, url) => {
    setShowCreateTaskModal(false);
    if (name.length > 0 && url.length > 0) {
      apiCreateLearnTask(name, url);
    }
  }

  return (
    <div>
      <Head />

      <CreateTask modalShow={showCreateTaskModal} onClose={onCreateTaskModalClose} onComplete={onCreateTaskComplete} />

      <div className='main-container'>
        <div className='container-center' style={{ maxWidth: '1200px' }}>

          <div className="configure-block-title">
            <h4> Learning Management </h4>
            <Button variant="outline-success" onClick={onCreateTaskClick}>New Task</Button>{' '}
          </div>
          <div className="configure-block-content">
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Task</th>
                  <th>Root Domain</th>
                  <th>Urls</th>
                  <th>Status</th>
                  <th>Create Time</th>
                  <th>Operation</th>
                </tr>
              </thead>
              <tbody>
                {learningTasks.map((task, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{task.name}</td>
                    <td>{task.root_url}</td>
                    <td>{task.url_size}</td>
                    <td>{task.status}</td>
                    <td>{ toLocalTime(task.created_at) }</td>
                    <td>{'Delete'}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>

        </div>
      </div>
      <Tail />
    </div>
  );
}

export default HomePage;
