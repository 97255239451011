
function Tail() {
    return (
      <footer>
        <div className='tail-container'>
          <div className='container-center top-border'>
            <p>Mangosteen © 2022 - 2024 山竹科技 v{process.env.REACT_APP_VERSION}</p>
            <p>Contact us: <a href="mailto:service@mangosteen.one">service@mangosteen.one</a></p>
          </div>
        </div>
      </footer>
    );
  }
  
  export default Tail;
  